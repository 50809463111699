import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// assets & components
import AboutECoachCard from 'components/pageECoach/aboutECoach/_index';
import AllChats from 'components/pageECoach/allChats/_index';
import ECoachSession from 'components/pageECoach/ECoachSession';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import FeedbackForm from 'components/pageECoach/FeedbackForm';
import Insights from 'components/pageECoach/insights/_index';
import Toolbar from 'components/pageECoach/Toolbar';

// constants
import routesConfig from 'constants/routesConfig.json';

// context
import { useUser } from 'contexts/UserContext';

// hooks
import useApi from 'hooks/useApi';
import { LoadingStatus, useFeedbackConsumer } from 'hooks/useFeedbackConsumer';

// style
import Ecoach from 'components/pageECoach/_styles/_index.style';

const chatGuideSetup = {
  chatStartEnded: false,
  convoPrep: { with_who: '', goal: '', worried_by: '' },
  displayPromptHelper: false,
  displaySampleCases: false,
  inProgress: false,
  promptHelper: { goal: '', theme: '' },
};

export default function ECoachPage() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { data: user } = useUser();
  const { id } = params;
  const pathname = location.pathname.toLowerCase();

  const [activeSession, setActiveSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState(activeSession?.steps || []);
  const [chatGuideData, setChatGuideData] = useState(chatGuideSetup);
  const [clickedPage, setClickedPage] = useState(null);

  const { data: session, callApi: getSession } = useApi();

  useEffect(() => {
    if (id && !activeSession)
      getSession(`${routesConfig.ECOACH.SESSIONS}${id}`);
  }, [activeSession, getSession, id]);

  useEffect(() => {
    if (session) {
      setLoading(false);
    }
    if (session.status === 'success') {
      setActiveSession(session.response);
      history.push({
        pathname: `${routesConfig.E_COACH_PAGE}/${session.response.id}`,
      });
    }
  }, [history, session]);

  const { feedbackStatus, fetchFeedbackStatus, tryCreate } =
    useFeedbackConsumer();

  const resetChatGuideData = () => setChatGuideData(chatGuideSetup);

  const handleNewChat = useCallback(
    (skipFeedbackFetching = false) => {
      if (!skipFeedbackFetching && activeSession) {
        fetchFeedbackStatus(activeSession.id);
      }

      resetChatGuideData();
      history.push({ pathname: clickedPage, search: '' });
    },
    [activeSession, history, fetchFeedbackStatus, clickedPage]
  );

  useEffect(() => {
    if (feedbackStatus.loadingStatus !== LoadingStatus.LOADED) return;
    if (!feedbackStatus.data.feedback_needed) setActiveSession(null);
  }, [feedbackStatus, setActiveSession]);

  const getPage = () => {
    if (pathname === routesConfig.E_COACH_ABOUT_PAGE)
      return <AboutECoachCard />;

    if (pathname === routesConfig.E_COACH_INSIGHTS_PAGE) {
      if (user.is_team_lead) return <Insights />;
      history.push(routesConfig.E_COACH_PAGE);
    }

    if (pathname === routesConfig.E_COACH_ALL_CHATS_PAGE)
      return (
        <AllChats
          activeSession={activeSession?.id}
          setLoadingTrue={() => setLoading(true)}
          handleNewChat={handleNewChat}
          resetChatGuideData={resetChatGuideData}
          setActiveSession={setActiveSession}
        />
      );

    return (
      <ECoachSession
        activeSession={activeSession}
        loading={loading}
        chatGuideData={chatGuideData}
        createSession={getSession}
        setChatGuideData={setChatGuideData}
        steps={steps}
        setSteps={setSteps}
      />
    );
  };

  return (
    <ErrorBoundary>
      <Ecoach>
        <Toolbar
          activeSession={activeSession}
          chatGuideData={chatGuideData}
          handleNewChat={handleNewChat}
          handleClickedPage={(page) => setClickedPage(page)}
        />
        <FeedbackForm
          feedbackStatus={feedbackStatus}
          handleCreate={(input) => {
            tryCreate(activeSession.id, input);
            setActiveSession(null);
            handleNewChat();
          }}
        />
        {getPage()}
      </Ecoach>
    </ErrorBoundary>
  );
}
