import styled, { keyframes } from 'styled-components';
import colours from 'constants/colours';

export const blink = keyframes`
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
`;

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Dot = styled.div`
  animation: ${blink} 1.4s infinite both;
  background-color: ${colours.neutralCool[300]};
  border-radius: 50%;
  height: 10px;
  margin: 0 5px;
  width: 10px;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;
