import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Divider } from '@mui/material';
import DocumentRow from 'components/pageECoach/documentDisplay/DocumentRow';

// styles
import * as Text from 'components/_styles/Text.style';

export default function DocumentList({ documentList, session }) {
  const { t } = useTranslation('pageEcoach');

  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        '&::before': {
          opacity: '1 !important',
        },
        '&.Mui-expanded::before': {
          opacity: '1 !important',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowRightIcon />}
        aria-controls="panel-content"
        id="panel-header"
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginRight: '10px',
            '&.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
          },
          padding: '0',
          '& .MuiAccordionSummary-content': {
            marginLeft: 0,
          },
        }}
      >
        <Text.P2Semibold>{t('Relevant company documents')}</Text.P2Semibold>
      </AccordionSummary>
      <AccordionDetails>
        <div id="accordionContentArea" style={{ paddingLeft: '20px' }}>
          <div
            id="accordionContent"
            style={{
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {documentList.map((doc, index) => (
              <div id={index} key={index}>
                <DocumentRow
                  language={doc.language}
                  docParentId={doc.parent.id}
                  bucketUrl={doc.url}
                  docTitle={doc.title}
                  session={session}
                />
                <Divider style={{ margin: '15px 0' }} />
              </div>
            ))}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

DocumentList.propTypes = {
  documentList: PropTypes.array,
  session: PropTypes.string,
};
