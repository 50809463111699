import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

// components
import ErrorMessage from 'components/pageLogin/ErrorMessage';
import Loader from 'components/pageECoach/loaders/Loader';
import VerificationInput from 'react-verification-input';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/pageLogin/_styles/Card.style';
import * as Text from 'components/_styles/Text.style';

export default function CodeCard() {
  const posthog = usePostHog();
  const { t } = useTranslation('pageLogin');
  const { data, callApi } = useApi();
  const { state } = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  const emailParam = searchParams.get('email');
  const decodedEmail = emailParam
    ? decodeURIComponent(emailParam).replace(/ /g, '+')
    : null;
  const loginCodeParam = searchParams.get('login_code');

  const [hideSubmissionError, setHideSubmissionError] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [code, setCode] = useState(loginCodeParam || '');
  const [error, setError] = useState('');

  const email = decodedEmail || state.email;

  const handleLogin = useCallback(() => {
    callApi('/login/token/', 'post', { email, login_code: code });
    posthog?.capture('user_submitted_login_code');
  }, [callApi, code, posthog, email]);

  const handleCodeReset = () => {
    setHideSubmissionError(true);
    setError('');
    setCode('');
  };

  const handleComplete = (inputCode) => {
    setError('');
    setCode(inputCode);
    setHideSubmissionError(false);
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (code.length !== 0 && email.length !== 0) {
      handleComplete(code);
      handleLogin();
    }
  }, [handleLogin, code, email]);

  useEffect(() => {
    if (data.status === 'success') {
      localStorage.setItem(
        'authorization',
        JSON.stringify({ token: data.response.token })
      );
      window.location.replace('/');
    }
    if (data.statusCode === 429) setError('too many attempts');
    if (data.statusCode === 400) setError('invalid code');
  }, [data]);

  const errorMessage = () => {
    if (error === 'invalid code')
      return (
        <ErrorMessage action={handleCodeReset} email={email} error={error} />
      );
    return <ErrorMessage email={email} error={error} />;
  };

  const StyledVerificationInput = (props) => (
    <Styled.VerificationWrapper>
      <VerificationInput {...props} />
    </Styled.VerificationWrapper>
  );

  return (
    <Styled.Card>
      <Styled.Header>
        <Text.H2>{t('codeCard.heading')}</Text.H2>
        {!showLoading && hideSubmissionError && (
          <Text.P2Semibold>{t('codeCard.subheading')}</Text.P2Semibold>
        )}
      </Styled.Header>
      {showLoading && (
        <div
          style={{ justifyContent: 'center', display: 'flex', width: '100%' }}
        >
          <Loader inline />
        </div>
      )}
      {!showLoading && hideSubmissionError && (
        <>
          <Styled.FormInput>
            <StyledVerificationInput
              inputProps={{ inputMode: 'numeric' }}
              length={6}
              onComplete={handleComplete}
              validChars="0-9"
            />
          </Styled.FormInput>
          <ErrorMessage email={email} />
        </>
      )}
      {!showLoading && !hideSubmissionError && errorMessage()}
    </Styled.Card>
  );
}
