import styled from 'styled-components';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import colours from 'constants/colours';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';

export const InputGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputTextArea = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${colours.neutralCool[50]}`,
    '& fieldset': {
      border: `2px solid ${colours.neutralCool[200]}`,
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${colours.neutralCool[200]}`,
    },
  },
  '& .MuiInputBase-input': {
    color: `${colours.neutralCool[900]}`,
  },
});

export const ButtonsColumn = styled('div')`
  align-items: end;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Card = styled(Cards.Card)`
  gap: 20px;
  max-width: 530px;
`;

export const Label = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[100]};
`;

export const PromptControls = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PromptControl = styled(Button)`
  border: 1px dashed ${colours.neutralWarm[900]};
  border-radius: 30px;
  gap: 5px;
  justify-content: unset;
  line-height: unset;
  max-width: 500px;
  padding: 10px 20px;
  text-align: left;
  text-transform: none;
  width: fit-content;

  p {
    color: ${colours.neutralWarm[900]};
  }

  svg {
    color: ${colours.neutralWarm[900]};
  }

  :hover {
    background-color: ${colours.neutralWarm[900]};

    p {
      color: ${colours.neutralWarm[50]};
    }

    svg {
      color: ${colours.shade[0]};
    }
  }
`;

export const PromptControlDark = styled(PromptControl)`
  background-color: ${colours.neutralCool[800]};
  p {
    color: ${colours.neutralCool[50]};
  }
  :hover {
    background-color: ${colours.neutralCool[700]};
  }
`;

export const PromptControlPrimary = styled(PromptControl)`
  background-color: ${colours.primary[500]};
  p {
    color: ${colours.shade[0]} !important;
  }
  :hover {
    background-color: ${colours.primary[600]};
  }
`;
