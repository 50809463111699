import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// assets
import { Communication } from 'untitledui-js';

// components
import * as Buttons from 'components/_styles/Buttons.style';
import Dialog from '@mui/material/Dialog';

// context
import { useUser } from 'contexts/UserContext';

// helpers
import bookingLink from 'helpers/bookingLink';

// styles
import colours from 'constants/colours';
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';
import * as Text from 'components/_styles/Text.style';
import * as StyledChat from 'components/pageECoach/_styles/ChatBubble.style';

// TODO: Separate feedback form and thanks modal and use isVisible as visibility state instead of feedbackStatus
/**
 * feedbackStatus is used instead of isVisible to handle refresh when
 * thanks message is displayed and we want to request a new feedback
 * */
export default function FeedbackForm({ feedbackStatus, handleCreate }) {
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();

  const [showOther, setShowOther] = useState(false);
  const [otherReason, setOtherReason] = useState('');
  const [showReason, setShowReason] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [feedback, setFeedback] = useState({ helpful: [], reason: null });
  const [showModal, setShowModal] = useState(false);

  const resetForm = () => {
    setShowModal(false);
    setShowOther(false);
    setOtherReason('');
    setShowReason(false);
    setShowThanks(false);
    setFeedback({ helpful: [], reason: null });
  };

  useEffect(() => {
    setShowModal(feedbackStatus?.data?.feedback_needed);
  }, [feedbackStatus]);

  const ANSWER_CHOICES = {
    YES: ['Yes', t('feedback.Yes')],
    NO: ['No', t('feedback.No')],
  };

  const DETAILS_CHOICES = {
    TOO_COMPLICATED: t('feedback.Too complicated'),
    NOT_RELEVANT: t('feedback.Not relevant'),
    NOT_ENOUGH_GUIDANCE: t('feedback.Not enough guidance'),
    OTHER: t('feedback.Other'),
  };

  const handleHelpful = (answerValue) => {
    const answer = Object.entries(ANSWER_CHOICES).find(
      ([, [value]]) => value === answerValue
    );

    setFeedback({ helpful: answer, reason: null });
    // setShowThanks(answer[1] === ANSWER_CHOICES.YES);
    setShowReason(answer[1] !== ANSWER_CHOICES.YES);
  };

  const handleBooking = () => {
    bookingLink(user.language);
    resetForm();
  };
  const handleReason = (key, reason) => {
    if (key === 'OTHER') setShowOther(true);
    else {
      setFeedback((prev) => ({ ...prev, reason }));
      // setShowThanks(true);
    }
  };

  const getFeedbackOptions = () => (
    <>
      {Object.entries(ANSWER_CHOICES).map(([key, [value, label]]) => (
        <Buttons.Secondary key={key} onClick={() => handleHelpful(value)}>
          {label}
        </Buttons.Secondary>
      ))}
    </>
  );

  const getReasonOptions = () => (
    <>
      {Object.entries(DETAILS_CHOICES).map(([key, value]) => (
        <Buttons.Secondary key={key} onClick={() => handleReason(key, value)}>
          {value}
        </Buttons.Secondary>
      ))}
    </>
  );

  const getOtherInput = () => (
    <>
      <StyledPromptControls.InputTextArea
        id="outlined-multiline-static"
        multiline
        onChange={(input) => setOtherReason(input.target.value)}
        rows={3}
        sx={{ maxWidth: '100%', width: '800px' }}
        value={otherReason}
      />
      <div
        style={{
          alignContent: 'center',
          cursor: 'pointer',
          display: 'flex',
          flexWrap: 'unset',
          justifyContent: 'space-between',
        }}
      >
        <Text.P2Underline onClick={() => handleReason('OTHER_REASON', 'Other')}>
          Skip
        </Text.P2Underline>
        <StyledChat.ButtonArea>
          <StyledChat.SubmitButton
            disabled={otherReason.length === 0}
            onClick={() => handleReason('OTHER_REASON', otherReason)}
            sx={{
              '&.Mui-disabled': {
                background: colours.neutralCool[100],
                color: '#c0c0c0',
              },
            }}
          >
            <Communication.Send01 strokeWidth={1} />
          </StyledChat.SubmitButton>
        </StyledChat.ButtonArea>
      </div>
    </>
  );

  useEffect(() => {
    if (
      feedback.helpful &&
      (ANSWER_CHOICES[feedback.helpful[0]] === ANSWER_CHOICES.YES ||
        feedback.reason)
    ) {
      handleCreate({
        helpful: feedback.helpful[1][0],
        reason: feedback.reason,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback]);

  const getContent = () => {
    if (showThanks) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <Text.P1>{t('feedback.Think we can do better?')}</Text.P1>
            <Text.P1>
              {t(
                'feedback.Your opinions and experiences will directly impact the features and improvements we prioritize.'
              )}
            </Text.P1>
            <div style={{ display: 'flex' }}>
              <Buttons.Secondary onClick={handleBooking}>
                {t('feedback.Free 15 min call with our experts')}
              </Buttons.Secondary>
              <Buttons.Secondary onClick={() => resetForm()}>
                {t('feedback.Not now')}
              </Buttons.Secondary>
            </div>
          </div>
        </div>
      );
    }

    if (showOther) return getOtherInput();
    if (showReason) return getReasonOptions();
    return getFeedbackOptions();
  };

  const getheader = () => {
    if (showThanks) return t('feedback.Thank you for your feedback!');
    if (showReason)
      return t("feedback.What don't you like about these suggestions?");
    return t('feedback.Did you find the eCoach helpful?');
  };

  return (
    <Dialog
      open={showModal}
      onClose={resetForm}
      PaperComponent={StyledPromptControls.Card}
    >
      <StyledChat.Headers>
        <Text.SubheadingSemibold>{getheader()}</Text.SubheadingSemibold>
      </StyledChat.Headers>
      {getContent()}
    </Dialog>
  );
}

FeedbackForm.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  feedbackStatus: PropTypes.object.isRequired,
};
