import styled from 'styled-components';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const TeamCardSection = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

export const Card = styled(Cards.Card)`
  height: unset;
  justify-content: space-between;
  overflow: hidden;
  width: fit-content;
  flex: 1 1 400px;

  @media (max-width: ${device.tablet}) {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const TopCard = styled(Card)`
  background: linear-gradient(279deg, #36475a 1.35%, #1a2634 102.58%);
  background-position: center;
  background-size: cover;
  flex: unset;

  h3,
  p {
    color: ${colours.neutralCool[50]};
  }

  svg path {
    stroke: ${colours.neutralCool[50]};
  }
`;

export const InsightsSection = styled('div')`
  display: flex;
  gap: 60px;
`;

export const Insight = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InsightLabel = styled('div')`
  align-items: center;
  display: flex;
  gap: 5px;

  svg {
    height: 17px;
    width: 17px;
  }
`;

export const InsightData = styled(Text.H3Bold)`
  font-size: 30px;
  line-height: 30px;
`;

export const TeamLabel = styled(Text.P2Semibold)`
  color: ${colours.neutralCool[600]};
`;
