import { useCallback, useState } from 'react';

function parseMultipart(blob, boundary) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const decoder = new TextDecoder();
      const text = decoder.decode(arrayBuffer);
      const parts = text.split(`--${boundary}`);

      const result = {};
      parts.forEach((part) => {
        if (part.includes('Content-Disposition')) {
          const [headers, body] = part.split('\r\n\r\n');
          if (headers.includes('name="metadata"')) {
            result.metadata = JSON.parse(body);
          } else if (headers.includes('name="file"')) {
            const startIndex = text.indexOf('\r\n\r\n') + 4; // Find the start of binary data
            const binaryData = arrayBuffer.slice(
              startIndex,
              arrayBuffer.byteLength - boundary.length - 8
            );
            result.pdf = new Blob([binaryData], { type: 'application/pdf' });
          }
        }
      });

      resolve(result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(blob);
  });
}

const useApi = () => {
  const [data, setData] = useState({
    status: 'loading',
    statusCode: '',
    response: null,
  });
  const authorization = localStorage.getItem('authorization');
  const authorizationToken = authorization
    ? `Token ${JSON.parse(authorization).token}`
    : null;

  const callApi = useCallback(
    (path = '', method = 'get', params = null, additionalHeaders = null) => {
      const url = process.env.REACT_APP_API_URL + path;
      let headers =
        authorizationToken && path !== '/login/'
          ? {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: authorizationToken,
            }
          : {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            };

      headers = { ...(additionalHeaders || {}), ...headers };

      let requestOptions;

      switch (method) {
        case 'post':
          requestOptions = {
            headers,
            method: 'POST',
            body: JSON.stringify(params),
          };
          fetch(url, requestOptions).then((response) => {
            if (response.status === 204) {
              setData({
                status: 'success',
                statusCode: response.status,
                response: {},
              });
            } else if (response.ok) {
              response.json().then((formattedData) =>
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: formattedData,
                })
              );
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
        case 'pdf':
          requestOptions = {
            headers: {
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              const contentType = response.headers.get('Content-Type');
              // Check if this is a multipart response
              if (contentType && contentType.includes('multipart/form-data')) {
                const boundaryMatch = contentType.match(/boundary=(.*)$/);
                const boundary = boundaryMatch ? boundaryMatch[1] : null;

                if (boundary) {
                  response.blob().then((blob) => {
                    parseMultipart(blob, boundary).then((parsedBlob) => {
                      const pdfUrl = window.URL.createObjectURL(parsedBlob.pdf);
                      setData({
                        status: 'success',
                        statusCode: response.status,
                        response: {
                          url: pdfUrl,
                          metadata: parsedBlob.metadata,
                        },
                      });
                    });
                  });
                } else {
                  throw new Error('Invalid multipart boundary');
                }
              }
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
        case 'photo':
          requestOptions = {
            headers: {
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response.blob().then((blob) => {
                const contentType = response.headers.get('Content-Type');
                const imageUrl = window.URL.createObjectURL(
                  new Blob([blob], {
                    type: contentType, // Will handle both image/jpeg and image/png
                  })
                );
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: { url: imageUrl },
                });
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
        case 'picture':
          requestOptions = {
            headers: {
              Accept: 'application/json',
              Authorization: authorizationToken,
            },
            method: 'POST',
            body: params,
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              setData({
                status: 'success',
                statusCode: response.status,
                response,
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
        case 'put':
          requestOptions = {
            headers,
            method: 'PUT',
            body: JSON.stringify(params),
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              if (response.status === 204) {
                // Handle 204 No Content response
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: null,
                });
              } else {
                // For other successful responses, attempt to parse JSON
                response
                  .json()
                  .then((formattedData) =>
                    setData({
                      status: 'success',
                      statusCode: response.status,
                      response: formattedData,
                    })
                  )
                  .catch(() => {
                    // Handle JSON parsing errors
                    setData({
                      status: 'error',
                      statusCode: response.status,
                      response: 'Invalid JSON response',
                    });
                  });
              }
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
        case 'delete':
          requestOptions = {
            headers,
            method: 'DELETE',
            body: JSON.stringify(params),
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              setData({
                status: 'success',
                statusCode: response.status,
                response,
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
        case 'powerpoint':
          requestOptions = {
            headers: {
              Accept: 'application/json',
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response.blob().then((blob) => {
                const pptUrl = window.URL.createObjectURL(
                  new Blob([blob], {
                    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  })
                );
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: { url: pptUrl },
                });
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
        case 'word':
          requestOptions = {
            headers: {
              Accept: 'application/json',
              Authorization: authorizationToken,
            },
            method: 'GET',
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response.blob().then((blob) => {
                const wordUrl = window.URL.createObjectURL(
                  new Blob([blob], {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  })
                );
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: { url: wordUrl },
                });
              });
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
        default:
          requestOptions = {
            headers,
          };
          fetch(url, requestOptions).then((response) => {
            if (response.ok) {
              response.json().then((formattedData) =>
                setData({
                  status: 'success',
                  statusCode: response.status,
                  response: formattedData,
                })
              );
            } else {
              setData({
                status: 'error',
                statusCode: response.status,
                response,
              });
            }
          });
          break;
      }
      return null;
    },
    [authorizationToken]
  );

  return { data, callApi };
};

export default useApi;
