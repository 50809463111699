import { usePostHog } from 'posthog-js/react';
import { UserInputType } from 'components/pageECoach/utils';

export default function usePosthogChatbarTracker() {
  const posthog = usePostHog();

  const captureSubmission = (inputType) => {
    switch (inputType) {
      case UserInputType.INITIAL_PROMPT:
        return posthog?.capture('chat_submission_initial_prompt');

      case UserInputType.BACK_AND_FORTH_CONVERSATION_INPUT:
        return posthog?.capture('chat_submission_back_and_forth');

      case UserInputType.RESOLUTION_SUMMARY_INPUT:
        return posthog?.capture('chat_submission_resolution_summary_input');

      case UserInputType.UNBLOCKER_ANSWER:
        return posthog?.capture('chat_submission_unblocker_answer');

      case UserInputType.UNBLOCKER_FOLLOW_UP_ANSWER:
        return posthog?.capture('chat_submission_unblocker_follow_up_answer');

      case UserInputType.CONVERSATION_PREP_WITH_WHO:
        return posthog?.capture('chat_submission_conversation_prep_with_who');

      case UserInputType.CONVERSATION_PREP_GOAL:
        return posthog?.capture('chat_submission_conversation_prep_goal');

      case UserInputType.CONVERSATION_PREP_WORRIED_BY:
        return posthog?.capture('chat_submission_conversation_worried_by');

      default:
        return '';
    }
  };
  return { captureSubmission };
}
