import styled from 'styled-components';
import * as Buttons from 'components/_styles/Buttons.style';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import * as Text from 'components/_styles/Text.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import HappyMemories from 'assets/happy_memories.png';

export const ToggleGroup = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  width: fit-content;

  svg {
    height: 15px;
    width: 15px;
    stroke-width: 2.5;
  }
`;

export const ToggleChip = styled('div')`
  align-items: center;
  background-color: ${colours.shade[0]};
  border: ${(props) =>
    props.type === 'ongoing'
      ? `1px solid ${colours.error[700]}`
      : `1px solid ${colours.neutralCool[700]}`};
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  padding: 5px 25px 5px 20px;

  p {
    color: ${(props) =>
      props.type === 'ongoing'
        ? colours.primary[900]
        : colours.neutralCool[900]};
  }

  &:hover {
    background-color: ${(props) =>
      props.type === 'ongoing'
        ? `${colours.error[200]}`
        : `${colours.neutralCool[200]}`};
    color: ${colours.primary[900]};
  }
`;

export const Card = styled(Cards.Card)`
  align-items: stretch;
  cursor: pointer;
  flex: 0 0 47%;
  height: unset;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: ${device.tablet}) {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const BottomBlur = styled('div')`
  background-image: linear-gradient(
    rgba(255, 0, 0, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 1)
  );
  bottom: 0;
  height: 60px;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const Date = styled(Text.P1Semibold)`
  color: ${colours.neutralCool[600]} !important;
`;

export const Bold = styled('b')`
  font-weight: 500;
`;

export const DeleteIcon = styled('div')`
  &:hover {
    svg path {
      stroke: ${colours.primary[500]};
    }
  }
`;

export const Status = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  width: fit-content;

  p {
    color: ${(props) =>
      props.status === 'resolved'
        ? colours.mint[800]
        : colours.neutralCool[600]};
  }

  svg {
    height: 15px;
    width: 15px;
    stroke-width: 2.5;
  }

  svg path {
    stroke: ${(props) =>
      props.status === 'resolved'
        ? colours.mint[800]
        : colours.neutralCool[600]};
  }
`;

export const Button = styled(Buttons.Primary)`
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url(${HappyMemories});
  background-size: cover;
  color: ${colours.primary[900]};

  &:hover {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      url(${HappyMemories});
    color: ${colours.primary[900]};
  }

  &:focus {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      url(${HappyMemories});
    color: ${colours.primary[900]};
  }

  &:active {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.1)
      ),
      url(${HappyMemories});
    color: ${colours.primary[900]};
  }
`;

export const Notification = styled('div')`
  align-items: center;
  background-color: ${colours.primary[600]};
  border-radius: 20px;
  display: flex;
  flex: 1;
  gap: 5px;
  justify-content: space-between;
  padding: 20px;

  p {
    color: ${colours.shade[0]};
  }

  svg {
    cursor: pointer;
    height: 15px;
    width: 15px;
    stroke-width: 2.5;
  }

  svg path {
    stroke: ${colours.shade[0]};
  }
`;
