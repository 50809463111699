import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// components
import CalendarPage from 'components/pageCalendar/_index';
import ChatroomPage from 'components/pageChatroom/_index';
import ECoachPage from 'components/pageECoach/_index';
import EvaluationPage from 'components/pageEvaluation/_index';
import JourneyIdPage from 'components/pageJourneyId/_index';
import JourneyListPage from 'components/pageJourneyList/_index';
import NotFound from 'components/page404/page404';
import Onboarding from 'components/pageOnboarding/_index';
import OrganizationPage from 'components/pageOrganization/_index';
import OwlProfilePage from 'components/pageProfile/_index';
import DocumentPage from 'components/pageECoach/documentDisplay/DocumentPage';
import SlideshowPage from 'components/pageSlideshow/_index';

// context
import { useUser } from 'contexts/UserContext';

// constants
import routesConfig from 'constants/routesConfig.json';
import userRoles from 'constants/userRoles.json';

function PageRoutes() {
  const { data: user, userRole } = useUser();

  if (!userRole || !user) return null;
  return (
    <Switch>
      <Route exact path={routesConfig.ROOT_PAGE}>
        {userRole === userRoles.MEMBER &&
        user.member?.onboarding_completed_at === null ? (
          <Redirect to={routesConfig.ONBOARDING_PAGE} />
        ) : (
          <Redirect to={routesConfig.E_COACH_PAGE} />
        )}
        {userRole === userRoles.OWL && (
          <Redirect to={routesConfig.JOURNEY_LIST_PAGE} />
        )}
        {userRole === userRoles.SUPPORT_ADMIN && (
          <Redirect to={routesConfig.SCHEDULE_PAGE} />
        )}
      </Route>

      <Route exact path={`${routesConfig.E_COACH_ABOUT_PAGE}`}>
        <ECoachPage />
      </Route>

      <Route path={`${routesConfig.E_COACH_ALL_CHATS_PAGE}`}>
        <ECoachPage />
      </Route>

      <Route exact path={`${routesConfig.E_COACH_PAGE}/:id?`}>
        <ECoachPage />
      </Route>

      <Route exact path={`${routesConfig.DOCUMENT_PAGE}/:id/pdf`}>
        <DocumentPage />
      </Route>

      <Route exact path={routesConfig.JOURNEY_LIST_PAGE}>
        <JourneyListPage />
        {userRole === 'support_admin' && (
          <Redirect to={routesConfig.SCHEDULE_PAGE} />
        )}
      </Route>

      <Route exact path={routesConfig.CHATROOM_PAGE}>
        <ChatroomPage />
      </Route>
      <Route exact path={routesConfig.OWL_PROFILE_PAGE}>
        <OwlProfilePage />
      </Route>

      <Route exact path={`${routesConfig.JOURNEY_ID_PAGE}/:id`}>
        <JourneyIdPage />
        {userRole === 'support_admin' && (
          <Redirect to={routesConfig.SCHEDULE_PAGE} />
        )}
      </Route>

      <Route exact path={routesConfig.SCHEDULE_PAGE}>
        {[userRoles.OWL, userRoles.SUPPORT_ADMIN].includes(userRole) ? (
          <CalendarPage />
        ) : (
          <Redirect to={routesConfig.JOURNEY_LIST_PAGE} />
        )}
      </Route>

      <Route exact path={routesConfig.BOOKINGS_PAGE}>
        {userRole === userRoles.MEMBER ? (
          <CalendarPage />
        ) : (
          <Redirect to={routesConfig.JOURNEY_LIST_PAGE} />
        )}
      </Route>

      <Route exact path={routesConfig.MEMBERS_PAGE}>
        {userRole === userRoles.ADMIN ? (
          <OrganizationPage />
        ) : (
          <Redirect to={routesConfig.JOURNEY_LIST_PAGE} />
        )}
      </Route>

      <Route exact path={routesConfig.SLIDESHOW_PAGE}>
        <SlideshowPage />
      </Route>

      <Route exact path={routesConfig.EVALUATION_PAGE}>
        <EvaluationPage />
      </Route>

      <Route exact path={routesConfig.ONBOARDING_PAGE}>
        <Onboarding />
      </Route>

      <Route component={NotFound} exact path="*" />
    </Switch>
  );
}

export default PageRoutes;
