import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// components
import ClickableListCard from 'components/pageECoach/ClickableListCard';
import ECoachResponse from 'components/pageECoach/cardTemplates/ECoachResponse';
import Loader from 'components/pageECoach/loaders/Loader';
import SuggestionItem from 'components/pageECoach/suggestions/SuggestionItem';
import SuggestionItemDetails from 'components/pageECoach/suggestions/SuggestionItemDetails';

// hooks & helpers
import { useEcoachItemsConsumer } from 'hooks/useEcoachItemsConsumer';

export default function SuggestionItemsCard({ activeSession, stepOutput }) {
  const { t } = useTranslation('pageEcoach');
  const { items, selectedItem, selectItem } = useEcoachItemsConsumer(
    activeSession.id,
    stepOutput
  );

  return (
    <ECoachResponse
      status={items.status}
      loader={<Loader type={'action plan'} />}
    >
      <ClickableListCard
        ItemDetailsComponent={SuggestionItemDetails}
        items={items.data}
        ListItemComponent={SuggestionItem}
        listTitle={t('Suggestions')}
        selectedItem={selectedItem}
        selectItem={selectItem}
      />
    </ECoachResponse>
  );
}

SuggestionItemsCard.propTypes = {
  activeSession: PropTypes.object,
  stepOutput: PropTypes.object,
};
