import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { Communication, Users } from 'untitledui-js';

// styles
import * as Insights from 'components/pageECoach/_styles/Insights.style';
import * as Text from 'components/_styles/Text.style';

export default function Card({ insight }) {
  const { t } = useTranslation('pageEcoach');

  return (
    <Insights.Card>
      <Insights.TeamLabel>{insight.site}</Insights.TeamLabel>
      <Insights.InsightsSection>
        <Insights.Insight>
          <Insights.InsightData>{insight.onboarding_rate}</Insights.InsightData>
          <Insights.InsightLabel>
            <Users.Users01 />
            <Text.P2Semibold>{t('Onboarded')}</Text.P2Semibold>
          </Insights.InsightLabel>
        </Insights.Insight>
        <Insights.Insight>
          <Insights.InsightData>{insight.session_count}</Insights.InsightData>
          <Insights.InsightLabel>
            <Communication.MessageSquare02 />
            <Text.P2Semibold>{t('Chats submitted')}</Text.P2Semibold>
          </Insights.InsightLabel>
        </Insights.Insight>
      </Insights.InsightsSection>
    </Insights.Card>
  );
}

Card.propTypes = {
  insight: PropTypes.object,
};
