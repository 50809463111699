import React from 'react';
import PropTypes from 'prop-types';
import { usePostHog } from 'posthog-js/react';
import { useHistory } from 'react-router-dom';

// constants
import routesConfig from 'constants/routesConfig.json';

// components
import { General } from 'untitledui-js';
import { Stack } from '@mui/material';
import CardDate from 'components/pageECoach/allChats/CardDate';
import Status from 'components/pageECoach/allChats/Status';

// styles
import colours from 'constants/colours';
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import * as Text from 'components/_styles/Text.style';

export default function Card({
  chat,
  getSession,
  handleConfirmDialogOpen,
  setLoadingTrue,
}) {
  const history = useHistory();
  const posthog = usePostHog();

  const clickSession = (pastSessionId) => {
    setLoadingTrue();
    getSession(`${routesConfig.ECOACH.SESSIONS}${pastSessionId}`);
    posthog?.capture('user_clicked_past_session');
    history.push(`${routesConfig.E_COACH_PAGE}/${pastSessionId}`);
  };

  return (
    <AllChats.Card onClick={() => clickSession(chat.id)} data-test-id="chat">
      <div style={{ display: 'flex', gap: '30px', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
          <Stack justifyContent={'space-between'} direction={'row'}>
            <CardDate chat={chat} />
            <AllChats.DeleteIcon>
              <General.Trash01
                onClick={(e) => handleConfirmDialogOpen(e, chat.id)}
                stroke={colours.neutralCool[600]}
                size="15"
                whileHover={colours.primary[500]}
              />
            </AllChats.DeleteIcon>
          </Stack>
          <Text.P2Semibold>{chat.title}</Text.P2Semibold>
        </div>
      </div>
      <Status chat={chat} />
    </AllChats.Card>
  );
}

Card.propTypes = {
  chat: PropTypes.object,
  getSession: PropTypes.func,
  handleConfirmDialogOpen: PropTypes.func,
  setLoadingTrue: PropTypes.func,
};
