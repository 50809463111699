import React from 'react';
import * as Styled from 'components/pageECoach/_styles/ThreeDots.style';

const ThreeDots = () => (
  <Styled.LoadingContainer>
    <Styled.Dot />
    <Styled.Dot />
    <Styled.Dot />
  </Styled.LoadingContainer>
);

export default ThreeDots;
