import React, { useCallback } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// components
import ClickableInput from 'components/pageECoach/ClickableInput';

// styles
import * as Page from 'components/pageECoach/_styles/ChatArea.style';
import * as StyledPromptControls from 'components/pageECoach/_styles/PromptControls.style';
import { UserInputType } from './utils';

export default function ECoachConfirmationInput({
  sendUserInput,
  inputType,
  alternativeOption,
}) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const getPosthogEvent = useCallback(() => {
    switch (inputType) {
      case UserInputType.CHALLENGE_VERIFICATION_CONFIRMATION_ANSWER:
        return 'eCoach_challenge_description';
      case UserInputType.RESOLUTION_TAKEN_ACTION_ANSWER:
        return 'eCoach_taken_resolution_action';
      case UserInputType.RESOLUTION_IS_RESOLVED_ANSWER:
        return 'eCoach_case_is_resolved';
      default:
        return '';
    }
  }, [inputType]);

  const onConfirm = useCallback(() => {
    sendUserInput(t('Yes'), inputType);
    posthog?.capture(`user_confirms_${getPosthogEvent()}`);
  }, [getPosthogEvent, inputType, posthog, sendUserInput, t]);

  const onDecline = useCallback(() => {
    sendUserInput(t('No'), inputType);
    posthog?.capture(`user_declines_${getPosthogEvent()}`);
  }, [getPosthogEvent, inputType, posthog, sendUserInput, t]);

  const onAlternative = useCallback(() => {
    alternativeOption.action();
    posthog?.capture(`user_clicks_${alternativeOption.text}`);
    // sendUserInput(alternativeOption.text, inputType);
    sendUserInput("I don't need to take any actions", inputType);
  }, [alternativeOption, inputType, posthog, sendUserInput]);

  return (
    <Page.UserSection>
      <StyledPromptControls.ButtonsColumn>
        <ClickableInput
          inputAction={onConfirm}
          inputText={t('Yes')}
          inputType="light"
        />
        <ClickableInput
          inputAction={onDecline}
          inputText={t('No')}
          inputType="light"
        />
        {alternativeOption && (
          <ClickableInput
            inputAction={onAlternative}
            inputText={alternativeOption.text}
            inputType="light"
          />
        )}
      </StyledPromptControls.ButtonsColumn>
    </Page.UserSection>
  );
}

ECoachConfirmationInput.propTypes = {
  alternativeOption: PropTypes.object,
  sendUserInput: PropTypes.func,
  inputType: PropTypes.number,
};
