import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import * as Text from 'components/_styles/Text.style';

export default function UnsubscribeConfirmation() {
  const { t } = useTranslation('pageEcoach');

  const [hideNotification, setHideNotification] = useState(false);

  if (hideNotification) return <></>;
  return (
    <AllChats.Notification>
      <Text.P2Semibold>
        {t("You've successfully unsubscribed from your weekly review.")}
      </Text.P2Semibold>
      <General.XClose onClick={() => setHideNotification(true)} />
    </AllChats.Notification>
  );
}

UnsubscribeConfirmation.propTypes = {
  chat: PropTypes.object,
  getSession: PropTypes.func,
  handleConfirmDialogOpen: PropTypes.func,
};
